import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IFooterItemQueryResult, IFooterItem } from '../../interfaces/IFooterItemQueryResult';
import { IGlobalConfigQueryResult } from '../../interfaces/IGlobalConfigQueryResult';
import { FabricIcon } from '../FabricIcon/FabricIcon';
import * as styles from './Footer.module.scss'

export interface IFooterProps{

}

export const Footer = () => {
    const queryResult: IFooterItemQueryResult & IGlobalConfigQueryResult = useStaticQuery(graphql`
        query{
            allFooterItem{
                nodes{
                    id,
                    Title,
                    Icon_x0020_name,
                    URL
                }
            }
            allGlobalConfig(filter: {key: {eq: "FooterCopyrightString"}}){
                nodes{
                    key
                    value
                }
            }
        }
    `);

    return(
        <footer className={ styles.footer }>
            <div className={ styles.footerIconGroupContainer }>
            {
                queryResult.allFooterItem.nodes.map((node: IFooterItem) => {
                    return(
                        <a className={ styles.footerIconContainer } key={ node.Id } href={ node.URL }>
                            <FabricIcon className={ styles.footerIcon } iconName={ node.Icon_x0020_name } />
                            <span className={ styles.footerIconLabel }>{ node.Title }</span>
                        </a>
                    )
                })
            }
            </div>
            { 
                queryResult.allGlobalConfig && queryResult.allGlobalConfig.nodes.length > 0 && (
                    <span className={ styles.copyrightText }>{ queryResult.allGlobalConfig.nodes[0].value }</span>
                )
            }
        </footer>
    )
}