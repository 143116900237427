// extracted by mini-css-extract-plugin
export var bodyGrid = "MenuFullScreen-module--body-grid--0c0c7";
export var centerAlignedBody = "MenuFullScreen-module--centerAlignedBody--d72dc";
export var childMenuContainer = "MenuFullScreen-module--childMenuContainer--49744";
export var childMenuIcon = "MenuFullScreen-module--childMenuIcon--8226c";
export var childMenuItem = "MenuFullScreen-module--childMenuItem--97488";
export var childMenuItemContainer = "MenuFullScreen-module--childMenuItemContainer--dd35c";
export var fullScreenMenu = "MenuFullScreen-module--fullScreenMenu--7d208";
export var menuFullScreenContainer = "MenuFullScreen-module--menuFullScreenContainer--8d722";
export var rootMenuItem = "MenuFullScreen-module--rootMenuItem--67872";
export var rootMenuItemContainer = "MenuFullScreen-module--rootMenuItemContainer--9a90b";
export var sectionGrid = "MenuFullScreen-module--sectionGrid--70779";
export var sectionGridAlignCenter = "MenuFullScreen-module--sectionGridAlignCenter--e37e5";