// extracted by mini-css-extract-plugin
export var blueButton = "Button-module--blue-button--31b47";
export var bodyGrid = "Button-module--body-grid--6a461";
export var button = "Button-module--button--b1113";
export var centerAlignedBody = "Button-module--centerAlignedBody--07300";
export var darkBlueButton = "Button-module--darkBlue-button--9f93b";
export var darkButton = "Button-module--dark-button--11659";
export var defaultButton = "Button-module--default-button--71cf7";
export var disabledButton = "Button-module--disabled-button--1bc25";
export var midBlueButton = "Button-module--midBlue-button--7230a";
export var sectionGrid = "Button-module--sectionGrid--8cf95";
export var sectionGridAlignCenter = "Button-module--sectionGridAlignCenter--c9860";