import * as React from 'react';

import * as styles from './MenuButton.module.scss';

export interface IMenuButtonProps{
    onClick: () => void;
}

export const MenuButton = (props: IMenuButtonProps) => {
    const { onClick } = props;
    
    return(
        <div className={ styles.menuContainer } onClick={ onClick }>
            <div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </div>
            <div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </div>
            <div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </div>
        </div>
    )
}