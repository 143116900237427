import * as React from 'react';
import { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MenuButton } from '../MenuButton/MenuButton';
import { MenuItem } from '../MenuItem/MenuItem';
import { Button, ButtonStyles } from '../Button/Button';
import { IMenuItemQueryResult, IMenuItem } from '../../interfaces/IMenuItemQueryResult';
import { AuthRequest, MSALAuthInstance } from '../../services/auth.service';
import { navigate } from '@reach/router';

import * as styles from './Header.module.scss';
import LogoNoText from '../../assets/logoNoText.asset.svg';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { MenuFullScreen } from '../MenuFullScreen/MenuFullScreen';
import { ChildMenuItemTile } from '../ChildMenuItemTile/ChildMenuItemTile';
import { FabricIcon } from '../FabricIcon/FabricIcon';

export interface IHeaderProps{
}

export const Header = (props: IHeaderProps) => {
    const [loggedInUser, setLoggedInUser] = useState<string>(null);
    const [fullScreenMenuIsOpen, setFullScreenMenuIsOpen] = useState<boolean>(false);

    const { instance, accounts } = useMsal();

    const menu: IMenuItemQueryResult = useStaticQuery(graphql`
        query{
            allMenuItem(sort: {order: ASC, fields: Displayorder}){
                nodes{
                    id,
                    originalId,
                    Title,
                    Parents{
                        LookupId
                    },
                    Iconname,
                    Submenutitle,
                    URL
                }
            }
        }
    `);

    useEffect(() => {
        const account = instance.getActiveAccount();
        if(account){
            setLoggedInUser(account.name);
        }
    },[accounts]);

    // useEffect(() => {
    //     if(accounts && accounts.length > 0){
    //         setLoggedInUser(accounts[0].name);

    //     }
    // },[accounts]);

    const onLoginClick = async () => {
        // window.localStorage.setItem("pbovicgovau_post_login_redirect", window.location.pathname);
        // const location = window.location || { pathname: "/" };
        // navigate("/secure/login", { state: { postAuthRoute: location }});

        const loginRes = await instance.loginRedirect(AuthRequest);
        console.log(loginRes);
    }

    return (
        <>
            <header className={ styles.header } style={{ position: fullScreenMenuIsOpen ? 'fixed' : 'relative', zIndex: 100}}>
                <a href="/"><LogoNoText className={ styles.lhLogo } /></a>
                <div className={ styles.menuContainer }>
                    <MenuButton onClick={ () => setFullScreenMenuIsOpen(!fullScreenMenuIsOpen) }  />
                    <div className={ styles.menuHeightElement }>&nbsp;</div>
                    <div className={ styles.menuFlexContainer }>
                    {
                        menu.allMenuItem.nodes.filter((node: IMenuItem) => !node.Parents || node.Parents.length == 0).map((node: any) => {
                            return (
                                    <MenuItem text={ node.Title } id={ node.id } key={ node.id } submenuTitle={ node.Submenutitle } childNodes={ menu.allMenuItem.nodes.filter((n: IMenuItem) => n.Parents && n.Parents.length > 0 && n.Parents.map(p => p.LookupId.toString()).indexOf(node.originalId) >= 0 ) } />
                                )
                            })
                    }
                    </div>
                </div>
                <div className={ styles.flexPadding }>&nbsp;</div>
                {/* <div>
                    <div className={ styles.userActionButton} >
                        <FabricIcon iconName='SignOut' />
                        <div className={ styles.loggedInUserContainer }>
                            <span>{ loggedInUser ? loggedInUser.match(/^\w|\s\w/gi).join("").replace(/\s/gi,"") : "" }</span>
                        </div>
                    </div>
                </div> */}
                <AuthenticatedTemplate>
                    <div className={ styles.buttonContainer }>
                        <div className={ styles.comboButton }>
                            <span>{ loggedInUser }</span>
                            <a href="/secure/logout"><FabricIcon className={ styles.signOutIcon } iconName='SignOut24Regular' /></a>
                        </div>
                        <Button text="My portal" classes={ ButtonStyles.defaultButton } href="/secure/portal" />
                        {/* <Button text="Andrew Test - Logout" classes={ ButtonStyles.blueButton } href="/secure/logout" /> */}
                    </div>

                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Button text="Login" classes={ ButtonStyles.blueButton } onClick={ onLoginClick }/>
                </UnauthenticatedTemplate>
            </header>
            <MenuFullScreen menuNodes={  menu.allMenuItem.nodes || [] } isOpen={ fullScreenMenuIsOpen } />
        </>
    );
}