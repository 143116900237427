
// This is the global configuration which allows a site to talk to SharePoint and other required services //

export interface ISiteGlobalConfig{
    MSAL_CLIENT_ID: string;
    MSAL_TENANT_ID: string;
    PRODUCTION_SITE_HOSTNAME: string;
    DEVELOPMENT_API_ROOT: string;
    CMS_SHAREPOINT_SITE_ID: string;
    CMS_SHAREPOINT_SITE_URL: string;
    CMS_OUTPUT_FILES_DRIVE_ID: string;
    GRAPH_ROOT_URL: string; // added this to config to account for future version changes //
    PUBLIC_BLOB_URL: string;
    CMS_CONTENT_LIST_ID: string;
    CMS_PAGE_LIST_ID: string;
    CMS_REQUESTS_LIST_ID: string;
    CMS_FORMS_LIST_ID: string;
    WORKFLOW_WITHDRAW_REQUEST: string;
    WORKFLOW_HOLD_REQUEST: string;
    WORKFLOW_PUBLIC_RELEASE: string;
    SP_TENANT_ROOT_URL: string;
    LEGACY_POLICY_COSTING_FORM_LIST_ID: string;
    LEGACY_ADVICE_FORM_LIST_ID: string;
    LEGACY_EXTRANET_SITE_ID: string;
}

export const SiteGlobalConfig: ISiteGlobalConfig = {
    SP_TENANT_ROOT_URL: "https://pbovicgovau.sharepoint.com",
    MSAL_CLIENT_ID: "5c30d8f7-8ee8-47fa-b4f7-1212b722d8bc",
    MSAL_TENANT_ID: "ba1e78b3-20bc-40c5-9517-32141dd9f1ff",
    PRODUCTION_SITE_HOSTNAME: "pbo.vic.gov.au",
    DEVELOPMENT_API_ROOT: "localhost:7071",
    // PRODUCTION_SITE_HOSTNAME: "pbostaticproxy.azurewebsites.net",
    CMS_SHAREPOINT_SITE_ID: "pbovicgovau.sharepoint.com,b4b5e60f-5e2b-43cb-bca0-e33a8aa901c5,45cf5dce-23de-4ce5-b4cd-e84b9a1d76f4",
    CMS_SHAREPOINT_SITE_URL: "https://pbovicgovau.sharepoint.com/sites/DEV-CMS",
    CMS_OUTPUT_FILES_DRIVE_ID:"b!D-a1tCtey0O8oOM6iqkBxc5dz0XeI-VMtM3oS5oddvR9q3ImvxIoTpoDBZEHFFp-",
    CMS_CONTENT_LIST_ID: "407169ad-dc20-4ef7-8e61-9d94fd689420",
    CMS_PAGE_LIST_ID: "d8e3dabb-a5e5-41fd-af69-ac1605e9b00b",
    CMS_FORMS_LIST_ID: "ccf8ea2e-65ca-4596-9e29-2cca71ad1d6f",
    GRAPH_ROOT_URL: "https://graph.microsoft.com/v1.0",
    PUBLIC_BLOB_URL: "https://pbowebstorage.blob.core.windows.net/costings",
    CMS_REQUESTS_LIST_ID: "41660c86-9841-4710-a03e-7d8c76f1539a",
    // WORKFLOW URIs
    WORKFLOW_WITHDRAW_REQUEST: "https://prod-27.australiasoutheast.logic.azure.com:443/workflows/cbb49097c2684527aac9f817158dbfa5/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=_I05DEN97Ql4ZDNP1yuUoINMvyMqNUT2g-oAPGY8vRE",
    WORKFLOW_HOLD_REQUEST: "https://prod-08.australiasoutheast.logic.azure.com:443/workflows/e74d8585f9a54ed0b489ea2f3b9f8fff/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=_HBwc7vDaPA_FwMuOAqfpd1qmsPqXkkVrsdyHgogYeU",
    WORKFLOW_PUBLIC_RELEASE: "https://prod-13.australiasoutheast.logic.azure.com:443/workflows/942435f948554ba58ed790645ad19bc5/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=7YdS99_cAUNsaOx0ACn7fMHmYt1Rko22xLtKetoy3cc",
    // LEGACY URLS
    LEGACY_POLICY_COSTING_FORM_LIST_ID: "212d3e3d-b4a7-4423-a981-83c44d7ea927",
    LEGACY_ADVICE_FORM_LIST_ID: "fb88156a-1b8c-4b57-a97a-2d75f9754450",
    LEGACY_EXTRANET_SITE_ID: "pbovicgovau.sharepoint.com,475ed666-a489-4fe8-80b8-8a25d84c5745,8ed1affd-9331-4e64-9933-c39ceb3caf8e"

}