import React, * as react from 'react';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from '@azure/msal-react';
import { AuthConfig } from './auth.service';
import { CustomNavigationClient } from './navigation.service';


// import { CustomNavigationClient } from "./utils/NavigationClient";

const msalInstance = new PublicClientApplication(AuthConfig);

export interface IWrapperProps{
    element: React.Component
}

export default function Wrapper({element}: IWrapperProps){
    // The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const navigationClient = new CustomNavigationClient();
    msalInstance.setNavigationClient(navigationClient);

    return (
        <MsalProvider instance={msalInstance}>
            {element}
        </MsalProvider>
    );
}