import * as React from 'react';
import { useEffect, useState } from 'react';
import * as fluentIcons from '@fluentui/react-icons'
import { FluentIconsProps, Question48Filled } from '@fluentui/react-icons'
import * as styles from './FabricIcon.module.scss'
import LogoNoTextOutline from '../../assets/logoNoTextOutline.asset.svg';

export interface IFabricIconProps extends React.HTMLAttributes<HTMLSpanElement>{
    iconName: string;
}

export const FabricIcon = (props: IFabricIconProps) => {
    const { iconName, className } = props;
    const availableIconKeys = Object.keys(fluentIcons);
    const allIcons: any = fluentIcons; // Setting the type as any seems to fix errors later when trying to access an icon via it's name - revisit this later//

    // Not sure why we need to use the below method
    // But when I try to store the icon FC in a state variable I get all manner of errors //
    return (
        <>
        {
            availableIconKeys.indexOf(iconName) >= 0 ? [iconName].map((v,i) => {
                const IconToRender = allIcons[v];
                
                return(
                    <IconToRender className={ className } key={ `fabricIcon-${i}` } />
                );
            }) : (
                // <Question48Filled className={ className } />
                // <div className={ className }>
                //     <div className={ styles.logo }></div>
                // </div>
                <LogoNoTextOutline className={ className } />
            )
        }
        </>
    )
}