import { PublicClientApplication, SilentRequest, Configuration, IPublicClientApplication, AccountInfo } from '@azure/msal-browser';
import { SiteGlobalConfig } from '../site.config';
import { graph } from '@pnp/graph';
import { BearerTokenFetchClient, FetchClient, IFetchOptions } from '@pnp/common';
import { BrowserFetchWithRetry, DefaultParse } from '@pnp/queryable';
import { navigate } from 'gatsby';
import { Timeline, TimelinePipe, dateAdd } from '@pnp/core';
import { useMsal } from '@azure/msal-react';
import { SPBrowser, spfi, DefaultHeaders, DefaultInit, RequestDigest } from '@pnp/sp';
import { IWeb } from '@pnp/sp/webs';
import '@pnp/sp/webs';


const isBrowser = typeof window !== "undefined";

export const AuthConfig: Configuration = {
    auth: {
        clientId: SiteGlobalConfig.MSAL_CLIENT_ID,
        redirectUri: !isBrowser || window.location.hostname === "localhost" ? "http://localhost:8000/secure/login" : `https://${SiteGlobalConfig.PRODUCTION_SITE_HOSTNAME}/secure/login`,
        postLogoutRedirectUri: !isBrowser || window.location.hostname === "localhost" ? "http://localhost:8000/" : `https://${SiteGlobalConfig.PRODUCTION_SITE_HOSTNAME}`,
        navigateToLoginRequestUrl: false,
        authority: `https://login.microsoftonline.com/${SiteGlobalConfig.MSAL_TENANT_ID}`
    },
    cache:{
        storeAuthStateInCookie: true,
        cacheLocation: 'localStorage'
    }
}

export const AuthRequest: SilentRequest = {
    scopes: ["User.Read", "Sites.Read.All", "Files.Read", "TermStore.Read.All", "Sites.ReadWrite.All"],
    authority: `https://login.microsoftonline.com/${SiteGlobalConfig.MSAL_TENANT_ID}`
}

export const SPAuthRequest: SilentRequest = {
    scopes: ["https://pbovicgovau.sharepoint.com/.default"],
    authority: `https://login.microsoftonline.com/${SiteGlobalConfig.MSAL_TENANT_ID}`
}

export const useSpFetchClient = (authInstance: PublicClientApplication|IPublicClientApplication) => {

    // const client = MsalClientSetup({
    //     auth: {
    //         authority: `https://login.microsoftonline.com/${SiteGlobalConfig.MSAL_TENANT_ID}`,
    //         clientId: SiteGlobalConfig.MSAL_CLIENT_ID,
    //         redirectUri: !isBrowser || window.location.hostname === "localhost" ? "http://localhost:8000/" : `https://${SiteGlobalConfig.PRODUCTION_SITE_HOSTNAME}`
    //     },
    // }, ["https://pbovicgovau.sharepoint.com/.default"]);

    // return client;

    const accounts = authInstance.getAllAccounts();
    authInstance.setActiveAccount(accounts[0]);

    const fetchClient = new GraphFetchClient(authInstance, SPAuthRequest);
    return fetchClient;
}

export const useGraphFetchClient = (authInstance: PublicClientApplication|IPublicClientApplication) => {
    const accounts = authInstance.getAllAccounts();
    authInstance.setActiveAccount(accounts[0]);
    const fetchClient = new GraphFetchClient(authInstance, AuthRequest);
    
    return fetchClient;
}

export class GraphFetchClient extends BearerTokenFetchClient{
    constructor(private authContext: PublicClientApplication|IPublicClientApplication, private loginRequest: SilentRequest){
        super(null);
    }

    public fetch: (url: string, options?: IFetchOptions) => Promise<Response> = async (url, options = {}) => {
        const token = await this.getToken();
        this.token = token;
        
        return super.fetch(url, { ...options, headers: {...(options.headers), accept: "application/json", "content-type": "application/json", authorization: `Bearer ${ this.token }`} });
    }

    public getToken: () => Promise<string> = async () => {
        try{
            const tokenRes = await this.authContext.acquireTokenSilent(this.loginRequest);
            return tokenRes.accessToken;
        }
        catch(err){
            console.log(JSON.stringify(err));

            if (this.requiresInteraction(err.errorCode)) {
                const redirectPath = window && window.location ? window.location.pathname : "/"
                window.localStorage.setItem('pbovicgovau_post_login_redirect', redirectPath);

                // this.authContext.acquireTokenRedirect(this.loginRequest);
                const authResult = await this.authContext.acquireTokenPopup(this.loginRequest);
                return authResult.accessToken;
                // navigate("/secure/login", { state: { postAuthRoute: { pathname: redirectPath } }});
            } else {
                throw err;
            }
        }
    }

    private requiresInteraction(errorCode: string) {
        console.log(errorCode);
        if (!errorCode || !errorCode.length) {
            return false;
        }

        return  errorCode === "consent_required" ||
                errorCode === "interaction_required" ||
                errorCode === "invalid_grant" ||
                errorCode === "login_required";
    }
}

// This is for V3 of @pnp/sp and @pnp/graph
export const MsalBehaviour = (fetchClient: GraphFetchClient): TimelinePipe => {
    return (instance: Timeline<any>) => {
        instance.on.auth(async (url: string, init: any) => {

            const token = await fetchClient.getToken();

            init.headers["Authorization"] = `Bearer ${token}`;

            return [url, init];
        });
    }
}

// export const useSP: (site: string) => IWeb = (site) => {
//     const { instance } = useMsal();
    
//     sp.setup({
//         sp: {
//             fetchClientFactory: () => {
//                 return useSpFetchClient(instance)
//             }
//         }
//     });

//     return sp.web;
// }

export const useSP: (site: string) => IWeb = (site) => {
    const { instance, accounts, inProgress } = useMsal();
    const spFetchClient = useSpFetchClient(instance);

    console.log(site);
    // const sp = spfi(site).using(SPBrowser(), MsalBehaviour(spFetchClient));
    const sp = spfi(site).using(DefaultInit(), DefaultHeaders(), BrowserFetchWithRetry(), DefaultParse(), MsalBehaviour(spFetchClient));

    return sp.web;
}



export class MSALAuthInstance {
    public isBrowser: boolean;
    public graphFetchClient: GraphFetchClient;
    private MSAL_CLIENT_ID: string = SiteGlobalConfig.MSAL_CLIENT_ID;
    private MSAL_TENANT_ID: string = SiteGlobalConfig.MSAL_TENANT_ID;
    private MSAL_PRODUCTION_REDIRECT_URI: string = `https://${SiteGlobalConfig.PRODUCTION_SITE_HOSTNAME}/secure/login/`;
    private MSAL_PRODUCTION_LOGOUT_REDIRECT_URI: string = `https://${SiteGlobalConfig.PRODUCTION_SITE_HOSTNAME}`;
    public msalConfig: Configuration;
    private loginRequest: SilentRequest;
    private msalInstance: PublicClientApplication;
    private pnpInitialised: boolean;
    public loginErrored: boolean;
    public loginErrorMessage: string;

    constructor(){
        this.isBrowser = typeof window !== "undefined";
        this.pnpInitialised = false;
        this.loginErrored = false;

        const MSAL_REDIRECT_URI = !this.isBrowser || window.location.hostname === "localhost" ? "http://localhost:8000/secure/login/" : this.MSAL_PRODUCTION_REDIRECT_URI;
        const MSAL_LOGOUT_REDIRECT_URI = !this.isBrowser || window.location.hostname === "localhost" ? "http://localhost:8000/" : this.MSAL_PRODUCTION_LOGOUT_REDIRECT_URI;

        this.msalConfig = {
            auth: {
                clientId: this.MSAL_CLIENT_ID,
                redirectUri: MSAL_REDIRECT_URI,
                postLogoutRedirectUri: MSAL_LOGOUT_REDIRECT_URI,
                navigateToLoginRequestUrl: false,
                authority: `https://login.microsoftonline.com/${this.MSAL_TENANT_ID}`
            },
            cache:{
                storeAuthStateInCookie: true,
                cacheLocation: 'localStorage'
            }
        };
        
        this.loginRequest = {
            scopes: ["User.Read", "Sites.Read.All", "Files.Read", "Sites.ReadWrite.All"],
            authority: `https://login.microsoftonline.com/${this.MSAL_TENANT_ID}`
        };

        this.msalInstance = new PublicClientApplication(this.msalConfig);
    }

    public loginUser = async () => {
        return new Promise((resolve, reject) => {
            try{
                this.msalInstance.handleRedirectPromise().then((response) => {
                    console.log("====== AUTH RESPONSE =======");
                    console.log(response);
                    this.initPnP();
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                    console.log("====== AUTH ERROR =======");
                    console.log(err);
                });
    
                this.msalInstance.loginRedirect(this.loginRequest);
                // this.msalInstance.loginPopup(this.loginRequest);
            }
            catch(err){
                console.log(err);
                this.loginErrored = true;
                this.loginErrorMessage = JSON.stringify(err);
                reject(err);
            }
        });
    }
    
    public isLoggedIn = async () => {
        try{
            const msalInstance = new PublicClientApplication(this.msalConfig);
            const account = msalInstance.getActiveAccount();
            console.log(account);
            if(account && !this.pnpInitialised){
                this.initPnP();
            }

            return account;
        }
        catch(err){
            return null;
        }
    }
    
    public logout = () => {
        const msalInstance = new PublicClientApplication(this.msalConfig);
        msalInstance.logoutRedirect();
    }

    private initPnP = () => {
        this.graphFetchClient = new GraphFetchClient(this.msalInstance, this.loginRequest);

        graph.setup({
            graph:{
                fetchClientFactory: () => this.graphFetchClient
            }
        });
    }

}


