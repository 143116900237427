import * as React from 'react';
import { useState, useEffect } from 'react';
import { IMenuItem } from '../../interfaces/IMenuItemQueryResult';
import { ChildMenuItemTile } from '../ChildMenuItemTile/ChildMenuItemTile';
import { FabricIcon } from '../FabricIcon/FabricIcon';

import * as styles from './MenuFullScreen.module.scss';

export interface IMenuFullScreenProps{
    menuNodes: IMenuItem[];
    isOpen: boolean;
}

export const MenuFullScreen = (props: IMenuFullScreenProps) => {
    const { menuNodes, isOpen } = props;

    const [rootNodes, setRootNodes] = useState<IMenuItem[]>([]);

    useEffect(() => {
        if(menuNodes && menuNodes.length > 0){
            const roots = menuNodes.filter(v => !v.Parents || v.Parents.length <= 0);
            setRootNodes(roots);
        }
    },[menuNodes]);

    return (
        <div className={ styles.menuFullScreenContainer } style={{ display: isOpen ? 'unset' : 'none' }}>
            <div className={ styles.fullScreenMenu }>
            {
                rootNodes && rootNodes.length > 0 && rootNodes.map((item) => {
                    const children = menuNodes.filter(v => v.Parents.map(p => p.LookupId.toString()).indexOf(item.originalId) >= 0);
                    return (
                        <div className={ styles.rootMenuItemContainer }>
                            <span className={ styles.rootMenuItem }>{ item.Title }</span>

                            <div className={ styles.childMenuContainer }>
                                {
                                    children && children.length > 0 && children.map(child => (
                                        <ChildMenuItemTile key={ child.id } title={ child.Title } iconName={ child.Iconname } url={ child.URL || "/"} />
                                    ))
                                }
                            </div>
                        </div>
                    )
                })
            }
            </div>
        </div>
    )
}