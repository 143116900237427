exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-document-tsx": () => import("./../../../src/pages/document/[...].tsx" /* webpackChunkName: "component---src-pages-document-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview/[...].tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-response-tsx": () => import("./../../../src/pages/response/[...].tsx" /* webpackChunkName: "component---src-pages-response-tsx" */),
  "component---src-pages-secure-legacy-tsx": () => import("./../../../src/pages/secure/legacy/[...].tsx" /* webpackChunkName: "component---src-pages-secure-legacy-tsx" */),
  "component---src-pages-secure-login-tsx": () => import("./../../../src/pages/secure/login.tsx" /* webpackChunkName: "component---src-pages-secure-login-tsx" */),
  "component---src-pages-secure-logout-tsx": () => import("./../../../src/pages/secure/logout.tsx" /* webpackChunkName: "component---src-pages-secure-logout-tsx" */),
  "component---src-pages-secure-portal-tsx": () => import("./../../../src/pages/secure/portal.tsx" /* webpackChunkName: "component---src-pages-secure-portal-tsx" */),
  "component---src-pages-secure-response-tsx": () => import("./../../../src/pages/secure/response/[...].tsx" /* webpackChunkName: "component---src-pages-secure-response-tsx" */),
  "component---src-pages-svg-chart-tsx": () => import("./../../../src/pages/svg/chart.tsx" /* webpackChunkName: "component---src-pages-svg-chart-tsx" */),
  "component---src-pages-uploader-tsx": () => import("./../../../src/pages/uploader/[...].tsx" /* webpackChunkName: "component---src-pages-uploader-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

