import * as React from 'react';
import { FabricIcon } from '../FabricIcon/FabricIcon';
import * as styles from './ChildMenuItemTile.module.scss';

export interface IChildMenuItemTileProps{
    title: string;
    iconName: string;
    url?: string;
}

export const ChildMenuItemTile = (props: IChildMenuItemTileProps) => {
    const { title, iconName, url } = props;

    return(
        <a className={ styles.childMenuItemTile} href={ url }>
            <FabricIcon className={ styles.icon } iconName={ iconName } />
            <span className={ styles.text }>
            {
                title
            }
            </span>
        </a>
    )
}