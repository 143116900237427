import * as React from 'react';
import { useState, useEffect } from 'react';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';

//import styles from './LayoutWrapper.module.scss';
import * as globalStyles from '../../globals.module.scss';

export interface ILayoutWrapperProps{
    element: React.ReactElement;
    props: any
}

const LayoutWrapper = ({element, props}: ILayoutWrapperProps) => {

    const excludedPaths = [/^\/svg\/chart/gi,/^\/secure\/preview/gi];
    const path = props.location.pathname;

    return excludedPaths.map((p) => path.match(p)).reduce((p: boolean,c: boolean) => p || c, false) ? (<>{ element }</>) : (
        <div className={ [globalStyles.bodyGrid, 'page-body-grid'].join(" ") }>
            <Header />
                {
                    element
                }
            <Footer />
        </div>
    )
}

export default LayoutWrapper;