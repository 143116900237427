import * as React from 'react';

export interface IButtonProps{
    text: string;
    href?: string;
    onClick?: () => void;
    classes?: string[];
    disabled?: boolean;
}

import * as styles from './Button.module.scss';

export const ButtonStyles = styles;

export const Button = (props: IButtonProps) => {
    const { text, classes, href, onClick, disabled } = props;

    return(
        <a className={ disabled ? [styles.button, styles.disabledButton].join(" ") : [styles.button].concat(classes || [styles.defaultButton]).join(" ") } href={ href } onClick={ onClick && !disabled ? onClick : (() => null) }>
            <span>{ text }</span>
        </a>
    )
}