// extracted by mini-css-extract-plugin
export var bodyGrid = "Header-module--body-grid--51398";
export var buttonContainer = "Header-module--buttonContainer--003ee";
export var buttonFlexGroup = "Header-module--buttonFlexGroup--14723";
export var centerAlignedBody = "Header-module--centerAlignedBody--4ccbd";
export var comboButton = "Header-module--comboButton--c6bb5";
export var flexPadding = "Header-module--flex-padding--04b5f";
export var header = "Header-module--header--357cc";
export var headerLhLogo = "Header-module--header-lh-logo--aa46d";
export var lhLogo = "Header-module--lh-logo--977eb";
export var loggedInUserContainer = "Header-module--loggedInUserContainer--4030b";
export var menuContainer = "Header-module--menu-container--9144e";
export var menuFlexContainer = "Header-module--menuFlexContainer--274cc";
export var menuFullScreenGridContainer = "Header-module--menuFullScreenGridContainer--2063d";
export var menuHeightElement = "Header-module--menuHeightElement--87b31";
export var sectionGrid = "Header-module--sectionGrid--d8f3a";
export var sectionGridAlignCenter = "Header-module--sectionGridAlignCenter--8d66c";
export var signOutIcon = "Header-module--signOutIcon--33a80";
export var userActionButton = "Header-module--userActionButton--b38bd";