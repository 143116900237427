// extracted by mini-css-extract-plugin
export var bodyGrid = "Footer-module--body-grid--d1bfb";
export var centerAlignedBody = "Footer-module--centerAlignedBody--18fa9";
export var copyrightText = "Footer-module--copyrightText--5927e";
export var divider = "Footer-module--divider--85273";
export var footer = "Footer-module--footer--2d913";
export var footerIcon = "Footer-module--footer-icon--b3ea9";
export var footerIconContainer = "Footer-module--footer-icon-container--a4edc";
export var footerIconGroupContainer = "Footer-module--footer-icon-group-container--935c9";
export var footerIconLabel = "Footer-module--footer-icon-label--db26c";
export var sectionGrid = "Footer-module--sectionGrid--98697";
export var sectionGridAlignCenter = "Footer-module--sectionGridAlignCenter--4aa42";